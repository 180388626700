.aside{
  padding-top: 60px;

  @include media('>=desktop'){
    padding-top: 120px;
  }
  @include media('>=widescreen'){
    padding-top: 180px;
  }

  .cols-holder{

    @include media('>=tablet'){
      display: flex;
      flex-flow: row wrap;
    }
  }

  .col{

    @include media('>=tablet'){
      width: 52%;
    }

    + .col{
      position: relative;
      z-index: 1;
      color: $white;
      @include pseudo(before, 0, -9999px, 0, -9999px);

      @include media('>=tablet'){
        width: 48%;
      }

      &:before{
        z-index: -1;
        background-color: #2a6c9c;
        background-image: linear-gradient(to top, rgba(19, 21, 55, 0) 0%, rgba(19, 21, 55, 0.2) 100%);

        @include media('>=tablet'){
          left: 70px;
        }
        @include media('>=desktop'){
          left: 88px;
        }
        @include media('>=widescreen'){
          left: 102px;
        }
      }
    }
  }

  .inner-wrap{
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 20px;

    @include media('>=tablet'){
      padding-top: 41px;
      padding-left: 100px;
      padding-bottom: 41px;
    }
    @include media('>=desktop'){
      padding-top: 81px;
      padding-left: 152px;
      padding-bottom: 81px;
    }
    @include media('>=widescreen'){
      padding-left: 212px;
      min-height: 705px;
    }
  }

  h2, .h2{
    margin: 2px 0 25px;

    @include media('>=desktop'){
      margin-bottom: 48px;
    }
  }

  .tel-link{
    font-weight: 400;
    letter-spacing: 1.62px;

    @include media('>=tablet'){
      margin-bottom: 23px;
    }
  }

  .tel-link,
  .mailto{
    font-size: 18px;
  }

  .adr{
    font: inherit;
    margin-bottom: 40px;
    display: block;

    @include media('>=tablet'){
      margin-bottom: 0;
    }
    @include media('>=widescreen'){
      display: flex;
      flex-flow: row nowrap;
      width: calc(100% + 54px);
      margin-left: -27px;
      margin-right: -27px;
      padding-top: 47px;
    }

    p{
      margin: 0;
    }
  }

  .address-col{

    @include media('>=widescreen'){
      padding-left: 27px;
      padding-right: 27px;
    }
  }

  .title{
    font-weight: 500;
  }

  dl{
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  dt,
  dd{
    font: inherit;
  }

  dt{
    margin: 0 3px 0 0;
    float: left;
    min-width: 33px;
  }

  dd{
    margin: 0;
    overflow: hidden;
  }

  .font-small{
    font-size: 16px;
    line-height: (30/16);
    color: $clr5;

    @include media('>=tablet'){
      padding-top: 55px;
    }

    p{
      margin: 0;
    }
  }

  .ft-address{
    font: inherit;
    margin-bottom: 15px;

    @include media('>=tablet'){
      margin-bottom: 44px;
    }
  }

  a{
    color: inherit;

    &.mailto{
      text-decoration: underline;
      letter-spacing: 2px;

      &:hover{
        color: inherit;
        text-decoration: none;
      }
    }

    &:hover{
      color: $brand-warning;
    }
  }
}

.ft-links{

  a{
    text-decoration: underline;

    &:hover{
      color: inherit;
      text-decoration: none;
    }
  }
}