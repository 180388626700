body{
  min-width: 320px;
  -ms-overflow-style: scrollbar;
  font-weight: 300;
  font-size: 18px;

  @include media('>=desktop'){
    line-height: (35/16);
  }
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6{

  a{
    color: inherit;

    &:hover{
      color: $link-hover-color;
    }
  }
}

h1, .h1{
  line-height: (28/19);
  color: $clr2;

  @include media('>=tablet'){
    font-size: 30px;
    line-height: (46/30);
  }
  @include media('>=desktop'){
    font-size: 34px;
    line-height: (50/34);
  }
}

h2, .h2{

  @include media('>=tablet'){
    font-size: 25px;
  }
  @include media('>=widescreen'){
    font-size: 30px;
  }
}

h3, .h3{

  @include media('>=desktop'){
    font-size: 24px;
  }
}

h4, .h4{

  @include media('>=desktop'){
    font-size: 20px;
  }
}

a,
button,
.form-control
input[type="submit"],
input[type="button"]{
  @extend %transition;
}

.form-control{
  -webkit-appearance: none;
}

#wrapper{
  width: 100%;
  position: relative;
  overflow: hidden;
  //min-height: 9999px;
}

.bg-cover{
  background-size: cover;
  background-position: 50% 50%;
  position: relative;

  .image-caption {
    display: block;
    position: absolute;
    bottom: -40px;
    z-index: 2;
    padding: 5px;
    font-style: italic;

    @include media("<tablet") {

    }

  }

  &.bg-cover01 {
    margin-top: -130px;
    min-height: 500px !important;

    @include media("<widescreen") {
      margin-top: -260px;
      min-height: 400px !important;
    }

    @include media("<tablet") {
      margin-bottom: 40px;
      margin-top: 0px !important;
    }

  }
}

.element-block{
  display: block;
}

.btn{
  border-radius: $border-radius-large;
  text-transform: uppercase;
  letter-spacing: 2px;

  @include media('>=desktop'){
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

.btn-default,
.btn-primary{

  &:hover,
  &:focus,
  &:active{
    background-color: $gray-lighter;
    border-color: $gray-lighter;
    color: $btn-default-color;
  }
}

.pager{

  li{
    > a,
    > span{
      border-radius: $border-radius-large;
    }
  }
}

.main-head{
  padding-top: 223px;

  @include media('>=tablet'){
    padding-top: 193px;
  }
  @include media('>=desktop'){
    padding-top: 220px;
  }

  h1, .h1{
    margin: 0 0 35px;


  }
}

.extra-wrap{

  @include media('>=desktop'){
    padding-left: 40px;
    padding-right: 40px;
  }
  @include media('>=widescreen'){
    padding-left: 80px;
    padding-right: 80px;
  }

  .container-fluid &,
  .container &{

    @include media('>=tablet'){
      margin-left: #{$grid-gutter-width / -2};
      margin-right: #{$grid-gutter-width / -2};
    }
  }
}

.posts-area{
  padding-bottom: 30px;

  @include media('>=tablet'){
    padding-bottom: 92px;
  }
  @include media('>=desktop'){
    padding-bottom: 92px;
  }
  @include media('>=widescreen'){
    padding-bottom: 122px;
  }
}

.align-center{

  @include media('>=tablet'){
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    width: 100%;
  }

  &.row-reverse{

    @include media('>=tablet'){
      flex-direction: row-reverse;
    }
  }

  h2, .h2{
    margin: 0 0 30px;

    @include media('>=widescreen'){
      margin-bottom: 52px;
    }
  }

  .description-wrap{
    margin-bottom: 30px;

    @include media('>=tablet'){
      width: 50%;
      padding: 23px 20px 113px;
      margin-bottom: 0;
    }
    @include media('>=desktop'){
      padding: 23px 63px 113px;
    }
    @include media('>=widescreen'){
      padding: 23px 126px 113px;
    }
  }

  .inner-wrap{

    @include media('>=tablet'){
      max-width: 330px;
    }
    @include media('>=widescreen'){
      max-width: 390px;
    }
  }

  .alignleft{
    padding-top: 50%;

    @include media('>=tablet'){
      width: calc(50% - 15px);
      margin-right: 15px;
      padding-top: 0;
      min-height: 530px;
    }
    @include media('>=desktop'){
      margin-right: 13px;
      margin-left: -40px;
      width: calc(50% + 27px);
      min-height: 530px;
    }
    @include media('>=widescreen'){
      min-height: 709px;
      margin-left: -80px;
      width: calc(50% + 67px);
    }

    img{
      @extend %img-responsive;
    }
  }
}

.post{
  position: relative;
  overflow: hidden;
  min-height: 250px;
  margin-bottom: 30px;

  @include media('>=tablet'){
    min-height: 300px;
  }
  @include media('>=desktop'){
    min-height: 340px;
  }
  @include media('>=widescreen'){
    min-height: 459px;
  }

  &.lg{

    @include media('>=tablet'){
      min-height: 366px;
    }
    @include media('>=desktop'){
      min-height: 406px;
    }
    @include media('>=widescreen'){
      min-height: 525px;
    }
  }

  /*
  &[data-tag]{
    @include pseudo(before, 0, 0, auto, auto);

    &:before{
      background-color: $brand-info;
      color: $white;
      content: attr(data-tag);
      padding: 8px 5px 11px;
      text-align: center;
      min-width: 85px;
      text-transform: capitalize;
      font: italic 400 18px/1 $font-family-serif;
      z-index: 2;
    }
  }
  */

  .post-link{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    &:hover{
      background-color: rgba($gray-base, 0.2);
    }
  }
}

.caption{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 30px 18px;
  z-index: 1;
  pointer-events: none;
  color: $white;
  display: flex;
  justify-content: flex-end;
  flex-flow: column nowrap;

  @include media('>=tablet'){
    padding: 30px 32px;
  }

  /*
  &:not(.caption-top){
    background-image: linear-gradient(180deg, rgba($gray-base, 0) 0%, rgba($gray-base, 0.4) 100%);
  }

  &.caption-top{
    justify-content: flex-start;
    background-image: linear-gradient(to top, rgba($gray-base, 0) 0%, rgba($gray-base, 0.4) 100%);
  }
  */

  .caption-wrap{
    max-width: 70%;
    background: rgba(0,0,0,0.4);
    padding: 20px;

    @include media('>=tablet'){
      max-width: 50%;
    }
  }

  h3, .h3{
    margin: 0 0 16px;
  }

  h4, .h4{
    margin: 0;
    text-transform: capitalize;
    font-style: italic;
  }
}

.post-col{
  position: relative;
  z-index: 1;

  @include media('>=tablet'){
    width: 50%;
    padding-left: #{$grid-gutter-width / 2};
    padding-right: #{$grid-gutter-width / 2};
    float: left;
  }

  &.pull-top{

    @include media('>=tablet'){
      margin-top: -43px;
    }
  }

  &.alignleft{

    @include media('>=tablet'){
      padding-left: 0;
    }
  }

  &.extra{

    @include media('>=tablet'){
      padding-right: 0;
    }
  }

  &.alignright{

    @include media('>=tablet'){
      margin-right: -15px;
      width: calc(50% + 15px);
    }
    @include media('>=desktop'){
      margin-right: -97px;
      width: calc(50% + 97px);
    }
    @include media('>=widescreen'){
      margin-right: -124px;
      width: calc(50% + 124px);
    }

    &.pull-top{

      @include media('>=tablet'){
        margin-top: -109px;
      }
    }
  }
}

.clip{
  position: absolute;
  z-index: -1;
  background-color: $body-bg;

  &.clip01{
    @include size(99%, 78px);
    left: 70px;
    top: -40px;

    @include media('>=tablet'){
      @include size(363px, 138px);
      left: -20px;
      top: -19px;
    }
    @include media('>=desktop'){
      width: 547px;
      left: -30px;
      top: -34px;
    }

    @include media("<tablet") {
      display: none;
    }
  }

  &.clip02{
    @include size(119px, 130px);
    bottom: -91px;
    right: -15px;
    z-index: 2;

    @include media('>=tablet'){
      bottom: -50px;
    }
    @include media('>=desktop'){
      right: -92px;
      @include size(189px, 150px);
      bottom: -71px;
    }
    @include media('>=widescreen'){
      right: -65px;
      bottom: -61px;
    }
  }

  &.clip03{
    z-index: 1;
    right: 0;
    top: -74px;
    @include size(169px, 89px);

    @include media('>=tablet'){
      top: -100px;
      left: 50%;
      right: auto;
      margin-left: 360px;
      @include size(9999px, 159px);
    }
    @include media('>=desktop'){
      margin-left: 389px;
    }
    @include media('>=widescreen'){
      margin-left: 531px;
    }
  }

  &.clip04{
    left: 100%;
    margin-left: -81px;
    bottom: -18px;
    @include size(9999px, 36px);
    z-index: 1;

    @include media('>=tablet'){
      bottom: 0;
    }
    @include media('>=widescreen'){
      margin-left: -111px;
    }
  }
}

blockquote{
  margin: 0 0 30px;
  padding: 0;
  border: 0;
  font: italic 400 21px/1.67 $font-family-serif;

  @include media('>=tablet'){
    font-size: 25px;
    margin-bottom: 43px;
  }
  @include media('>=widescreen'){
    font-size: 30px;
  }

  .post-col &{

    @include media('>=desktop'){
      margin-left: -26px;
      max-width: 87%;
    }
  }

  q,
  p{
    quotes: none;
    font: inherit;
    display: block;
    padding-left: 24px;
    position: relative;
    @include pseudo(before, 0, auto, auto, 0);

    &:before,
    &:after{
      color: $gray-lighter;
      font-size: 50px;
      @include vertical(inline-block, middle);
    }

    &:before{
      content: '“';
      top: -20px;
    }

    &:after{
      content: '”';
      margin-left: 6px;
      margin-top: -40px;
      margin-bottom: -37px;
    }
  }
}

.cols-center{

  @include media('>=tablet'){
    display: flex;
    align-items: center;
    flex-flow: row wrap;
  }

  &.row{

    &:after,
    &:before{

      @include media('>=tablet'){
        display: none;
      }
    }
  }
}

.inner-row{

  @include media('>=tablet'){
    margin-left: ($grid-gutter-width / -2);
    margin-right: ($grid-gutter-width / -2);
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
  }

  .col{

    @include media('>=tablet'){
      padding-left: ($grid-gutter-width / 2);
      padding-right: ($grid-gutter-width / 2);
    }
  }

  .col6{

    @include media('>=tablet'){
      width: 50%;
    }
  }
}

.history-block{
  position: relative;
  z-index: 1;
  background-color: $clr3;
  padding-top: 35px;
  margin-bottom: 80px;

  @include media('>=tablet'){
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 130px;
  }

  h2, .h2{
    margin: 0 0 35px;
    padding-bottom: 25px;
    background-image: linear-gradient(180deg, rgba($brand-info,0) 0%, rgba($brand-info,0) 50%, rgba($brand-info,1) 50%, rgba($brand-info,1) 100%);
    background-size: 53px 6px;
    background-repeat: no-repeat;
    background-position: 0 100%;

    @include media('>=tablet'){
      padding-bottom: 42px;
    }
  }

  .text-holder{
    margin-bottom: 30px;

    @include media('>=tablet'){
      max-width: 311px;
      padding-top: 53px;
      padding-bottom: 26px;
    }
    @include media('>=widescreen'){
      max-width: 421px;
    }
  }

  .service-link{
    position: absolute;
    left: 0;
    top: 100%;
    font-weight: 500;
    font-size: 16px;
    line-height: (26/16);
    padding-top: 35px;
    padding-left: 40px;
    right: 15px;
    @include pseudo(before, 0, auto, 0, 22px);
    @include pseudo(after, auto, auto, 0, 16px);

    @include media('>=tablet'){
      right: -40px;
      margin-left: calc(50% + 22px);
      max-width: 46%;
      padding-top: 65px;
      padding-left: 73px;
    }
    @include media('>=desktop'){
      letter-spacing: 2px;
      max-width: 50%;
    }
    @include media('>=widescreen'){
      max-width: 421px;
    }

    &:before{
      border-left: 1px dotted $brand-warning;
      bottom: 9px;

      @include media('>=tablet'){
        left: 0;
      }
    }

    &:after{
      border-radius: 100%;
      @include size(13px);
      border: 3px solid $brand-warning;
      background-color: $clr4;
      bottom: 3px;

      @include media('>=tablet'){
        transform: translateX(-50%);
        left: 0;
      }
    }

    > span{

    }

    a{
      color: inherit;

      &:hover{
        color: $brand-warning;
      }
    }
  }
}

.list-history{
  color: $clr1;
  position: relative;
  margin-bottom: -25px;
  @include pseudo(before, 9px, auto, 0, 0);

  @include media('>=tablet'){
    padding: 85px 0 20px;
    margin-bottom: 0;
  }
  @include media('>=widescreen'){
    max-width: 89%;
  }

  &:before{
    border-left: 1px solid $brand-warning;
    left: 7px;

    @include media('>=tablet'){
      top: 0;
    }
  }

  > li{
    position: relative;
    padding-left: 29px;
    margin-bottom: 25px;
    @include pseudo(before, 0, auto, auto, 0);

    @include media('>=tablet'){
      padding-left: 79px;
      margin-bottom: 45px;
    }

    &:before{
      border-radius: 100%;
      @include size(13px);
      border: 3px solid $brand-warning;
      background-color: $clr4;
      top: 6px;
      left: 1px;
    }
  }

  h3, .h3{
    margin: 0 0 13px;
    color: $gray-base;
    font-size: 20px;
    text-transform: capitalize;

    @include media('>=tablet'){
      font-size: 20px;
    }
    @include media('>=widescreen'){
      font-size: 20px;
    }
  }

  p{
    margin: 0;
  }
}

.visual-block{
  padding-top: 20px;
  padding-bottom: 20px;

  @include media('>=tablet'){
    padding-top: 41px;
    padding-bottom: 41px;
  }
  @include media('>=widescreen'){
    padding-top: 81px;
  }

  .text-holder{
    background-color: $brand-primary;
    color: $white;
    position: relative;
    padding: 26px 30px 45px;

    @include media('>=tablet'){
      margin-left: 1px;
      margin-top: 55px;
      position: relative;
      z-index: 1;
    }
    @include media('>=desktop'){
      padding: 46px 60px 71px;
      margin-top: 75px;
    }
    @include media('>=widescreen'){
      padding: 76px 108px 91px;
    }
  }

  h2, .h2{
    margin: 0 0 25px;
    padding-bottom: 30px;
    background-image: linear-gradient(180deg, rgba($brand-warning,0) 0%, rgba($brand-warning,0) 50%, rgba($brand-warning,1) 50%, rgba($brand-warning,1) 100%);
    background-size: 53px 6px;
    background-repeat: no-repeat;
    background-position: 0 100%;

    @include media('>=desktop'){
      margin-bottom: 48px;
    }
  }

  .visual{
    overflow: hidden;
    padding-top: 80%;

    @include media('>=xs-phone'){
      padding-top: 50%;
    }
    @include media('>=tablet'){
      min-height: 436px;
      margin-right: -111px;
      padding-top: 0;
    }
    @include media('>=desktop'){
      min-height: 736px;
    }
  }

  img{
    @extend %img-responsive;
  }
}

.widget-text{
  padding-top: 35px;
  padding-bottom: 35px;

  @include media('>=desktop'){
    padding-top: 66px;
    padding-bottom: 70px;
  }
  @include media('>=widescreen'){
    padding-top: 96px;
    padding-bottom: 120px;
  }

  h2, .h2{
    margin: 0 0 25px;

    @include media('>=tablet'){
      margin-bottom: 44px;
    }
  }

  .col6{

    @include media('>=tablet'){
      width: 58%;
    }
  }
}

.slideshow {
  position: relative;

  &.gallery-block{
    margin-left: #{$grid-gutter-width / -2};
    margin-right: #{$grid-gutter-width / -2};

    @include media('>=desktop'){
      margin-left: 0;
      margin-right: -40px;
    }
    @include media('>=widescreen'){
      margin-right: -80px;
    }

    .bg-cover{
      width: 100%;
      padding-top: 54.1%;

      @include media('>=desktop'){
        min-height: 736px;
        padding-top: 0;
      }
    }
  }

  .slide {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;

    &.active {
      z-index: 2;
    }
  }
}
.slideshow-btns{
  position: absolute;
  bottom: 0;
  z-index: 3;
  right: 0;
  @include size(100%, 40px);
  max-width: 80px;
  display: flex;
  align-items: stretch;
  flex-flow: row nowrap;
  background-color: $body-bg;

  @include media('>=tablet'){
    left: 50%;
    margin-left: 84px;
    height: 65px;
    width: 9999px;
    max-width: none;
    right: auto;
  }
  @include media('>=widescreen'){
    height: 105px;
  }

  .btn-prev,
  .btn-next{
    display: flex;
    align-items: center;
    justify-content: center;
    @include size(40px, 100%);
    color: $brand-info;
    font-size: 20px;
    padding: 5px;
    text-align: center;

    @include media('>=tablet'){
      font-size: 41px;
      width: 107px;
    }

    &.disabled{
      opacity: 0.4;
      pointer-events: none;
    }

    &:hover{
      color: $brand-warning;
    }
  }

  .btn-prev{

    +.btn-next{
      border-left: 1px solid $gray-light;
    }
  }
}

.image-slideshow {
  position: relative;

  .slide {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;

    &.active {
      z-index: 2;
    }
  }

  .bg-cover{
    @include size(100%);
  }
}

#contact{
  margin-top: -130px;
  padding-top: 130px;

  @include media('>=tablet'){
    margin-top: -160px;
    padding-top: 160px;
  }
  @include media('>=desktop'){
    margin-top: -160px;
    padding-top: 160px;
  }
}