// list reset
%listreset {
  margin: 0;
  padding: 0;
  list-style: none;
}

// clearfix
%clearfix {
 &:after {
    content: '';
    display: block;
    clear: both;
  }
}

.clearfix {
  @extend %clearfix;
}

// transition
%transition {
 transition: 0.3s ease;
 transition-property: color, background, border, visibility, opacity, box-shadow, transform;
}

//img responsive
%img-responsive{
  vertical-align: top;
  max-width: 100%;
  height: auto;
}

// justify nav
%justify {
 text-align: justify; 
 font-size: 1px;
 line-height: 0px;
 > * {
  display: inline-block;
  vertical-align: top;
  text-align: left;
  font-size: $font-size-base;
  line-height: $line-height-base;
 }
 &:after { 
  content: '';
  width: 100%;
  display: inline-block;
  vertical-align: top;
 }
}



/// * Helper class to truncate and add ellipsis to a string too long for it to fit
/// * on a single line.
/// * 1. Prevent content from wrapping, forcing it on a single line.
/// * 2. Add ellipsis at the end of the line.

.ellipsis {
  white-space: nowrap; /* 1 */
  text-overflow: ellipsis; /* 2 */
  overflow: hidden;
}

