// custom variables and override bootstrap variables

//== Colors
$gray-base:                                                         #000 !default;
$white:                                                             #fff;
$gray-light:                                                        #c3c1c4;
$gray-lighter:                                                      #d8d8d8;
$brand-primary:                                                     #131537;
$brand-info:                                                        #2a6c9c;
$brand-warning:                                                     #c49f6d;
$clr1:                                                              #5c401b;
$clr2:                                                              #003f6d;
$clr3:                                                              #f8efe2;
$clr4:                                                              #efe1cf;
$clr5:                                                              #afcce2;
$clr6:                                                              #d1d8dd;

//== Scaffolding
$text-color:                                                        $brand-primary;
$link-color:                                                        $clr1;
$link-hover-decoration:                                             none;

//== Typography
$font-family-sans-serif:                                            "Mukta", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-serif:                                                 "Lora", Georgia, "Times New Roman", Times, serif;
$font-size-base:                                                    16px;
$line-height-base:                                                  (26/16);
$font-size-h1:                                                      19px;
$font-size-h2:                                                      18px;
$font-size-h3:                                                      19px;
$font-size-h4:                                                      17px;
$font-size-h5:                                                      16px;
$font-size-h6:                                                      15px;
$headings-font-family:                                              $font-family-serif;
$headings-font-weight:                                              400;

//== Components
$padding-base-vertical:                                             7px;
$padding-base-horizontal:                                           18px;
$border-radius-base:                                                0;
$border-radius-large:                                               33px;
$component-active-color:                                            $white;
$component-active-bg:                                               $brand-info;

//== Buttons
$btn-font-weight:                                                   500;
$btn-default-color:                                                 $brand-primary;
$btn-default-bg:                                                    $white;
$btn-default-border:                                                $gray-lighter;
$btn-primary-color:                                                 $btn-default-color;
$btn-primary-bg:                                                    $white;
$btn-primary-border:                                                $btn-default-border;

//== Forms
$input-bg:                                                          $white;
$input-color:                                                       $brand-primary;
$input-border:                                                      $gray-lighter;
$input-border-focus:                                                $brand-primary;

//== Dropdowns
$dropdown-border:                                                   $gray-light;
$dropdown-fallback-border:                                          $dropdown-border;
$dropdown-divider-bg:                                               $dropdown-border;
$dropdown-link-hover-color:                                         $white;
$dropdown-link-hover-bg:                                            $brand-info;

//== Media queries breakpoints
$screen-lg:                                                         1341px;

//== Container sizes
$container-lg:                                                      1311px;