@font-face {
  font-family: 'icomoon';
  src:
    url('../fonts/icomoon.ttf?frlx5s') format('truetype'),
    url('../fonts/icomoon.woff?frlx5s') format('woff'),
    url('../fonts/icomoon.svg?frlx5s#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="ico-"], [class*=" ico-"] {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ico-up:before {
  content: "\e900";
}
.ico-left:before {
  content: "\e901";
}
.ico-down:before {
  content: "\e902";
}
.ico-right:before {
  content: "\e903";
}
