#header{
  padding-top: 15px;
  padding-bottom: 25px;
  background-color: $body-bg;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
  transition: padding 0.2s ease;
  min-width: 320px;

  @include media('>=tablet'){
    padding-top: 25px;
  }
  @include media('>=desktop'){
    padding-top: 45px;
    padding-bottom: 58px;
  }

  .nav {
	@include media("<=tablet") {
		display: flex;
		justify-content: space-between;
		overflow-x: scroll;
	}
  }


  &.fixed-position{

    @include media('>=tablet'){
      padding-top: 25px;
      padding-bottom: 25px;
    }
    @include media('>=desktop'){
      padding-top: 25px;
      padding-bottom: 25px;
    }
    @include media('>=widescreen'){
      padding-top: 25px;
      padding-bottom: 25px;
    }

    .logo{

      @include media('>=tablet'){
        max-width: 111px;
      }
      @include media('>=desktop'){
        max-width: 111px;
      }
      @include media('>=widescreen'){
        max-width: 111px;
      }

      &:before{

        @include media('>=desktop'){
          left: -10px;
          right: -10px;
          top: -10px;
          bottom: -10px;
        }
      }
    }
  }

  .item-link{
    float: right;
    min-width: 110px;
    margin-left: 15px;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: center;
    color: $btn-default-color;
    font-weight: $btn-font-weight;
    position: relative;
    z-index: 1;
    margin-top: 12px;
    padding-top: 9px;
    padding-bottom: 9px;

    @include pseudo(before, auto, 0, 0, 0);
    @include pseudo(after, auto, -9999px, 0, -9999px);

    @include media('>=tablet'){
      min-width: 131px;
    }
    @include media('>=desktop'){
      margin-top: 30px;
    }

    @include media("<=widescreen") {
      min-width: 90px;
    }

    @include media("<=desktop") {
      min-width: 90px;
    }

    @include media("<=tablet") {
      float: none;
      display: inline-block;
	  font-size: 14px;
	  margin: 0 5px 0 0;
	  padding: 10px 5px;
    }

    @include media("<xs-phone") {
	  font-size: 13px;
	  padding: 10px 3px;
    }

    &:before{
      height: 2px;
      background-color: $brand-warning;
      transition: height 0.2s ease;
      z-index: -1;
    }

    &:after{
      border-bottom: 1px solid $clr6;
      pointer-events: none;
      z-index: -2;
    }

    &:hover{

      &:before{
        height: 100%;
      }
    }
  }
}

.logo{
  max-width: 71px;
  margin-right: 15px;
  margin-bottom: -80px;
  float: left;
  position: relative;
  z-index: 3;
  transition: max-width 0.2s ease;
  @include pseudo(before, -10px, -10px, -10px, -10px);

  @include media('>=tablet'){
    max-width: 111px;
  }
  @include media('>=desktop'){
    max-width: 201px;
  }

  @include media("<=tablet") {
    float: none;
    display: block;
    width: 100%;
    height: auto;
    margin: 0;
    text-align: center;
    max-width: none;

    a {
      display: block;
      text-align: center;
    img {
      display: inline-block;
      margin: 0px auto;
      max-width: 70px;
    }
    }
  }

  &:before{
    background-color: $white;
    z-index: -1;
    pointer-events: none;

    @include media('>=desktop'){
      left: -30px;
      right: -30px;
      top: -30px;
      bottom: -30px;
    }
  }

  img{
    @extend %img-responsive;
  }
}